import React, { useState } from 'react'
import Alert from '../../../modal/Alert/Alert'
import './reg.css'

const RegPage = () => {
    const [errors, setErrors] = useState<string[]>([])
    const [login, setLogin] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [pass, setPass] = useState<string>("")
    const [passRep, setPassRep] = useState<string>("")
    const [checkbox, setCheckbox] = useState<boolean>(false)

    const submitFunc = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (login === "") {
            setErrors([...errors, "Login is empty"])
        }

        if (email === "" && email.indexOf('@') !== -1) {
            setErrors([...errors, "Email is empty"])
        }

        if ((pass !== passRep) || (pass === "" && passRep === "")) {
            setErrors([...errors, "Passwords not mach or empty"])
        }

        if (checkbox === false) {
            setErrors([...errors, "You agree with Terms?"])
        }

        if (errors.length !== 0) return console.log(errors)

        alert("logined")
    }

    return (
        <>
            <div className='notification-box'>
                {errors.map((text) => {
                    return <Alert key={Math.random()} errorsMod={setErrors} type='error' title='Error'>{text}</Alert>
                })}
            </div>
            <div className='login-container'>
                <h1>Sign Up</h1>
                <form onSubmit={submitFunc}>
                    <input onChange={(e) => {setLogin(e.target.value)}} type='username' placeholder='Username'/>
                    <input onChange={(e) => {setEmail(e.target.value)}} type='email' placeholder='noreply@example.com'/>
                    <input onChange={(e) => {setPass(e.target.value)}} type='password' placeholder='Password'/>
                    <input onChange={(e) => {setPassRep(e.target.value)}} type='password' placeholder='Repeat password'/>
                    <div>
                        <label className='checkbox'>
                            <input type={'checkbox'} onChange={() => {setCheckbox(!checkbox)}} name="remember"/>
                            I agree to the&nbsp;
                        </label>
                        <a className='term' href='#term'>Term</a>
                    </div>
                    <p>Have an account? <a href='/sign_in'>Log In</a></p>
                    <button type='submit'>Sign In</button>
                </form>
            </div>
        </>
    )
}

export default RegPage;