import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>DAGON PROJECT HERE</h1>
        <p>by LinearDev</p>
        <a href='lineardev.net'>Back on home page</a>
      </header>
    </div>
  );
}

export default App;
