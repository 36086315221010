import Layout from '../Layout';
import './dashboard.css'

function Dashboard() {
    return (
        <Layout>
            <div>index</div>
        </Layout>
    )
}

export default Dashboard;