import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faRobot } from '@fortawesome/free-solid-svg-icons'
import { faScroll } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import avatar from "../../images/icons/favicon.png"
import '../../index.css'
import './layout.css'

type props = {
    children: React.ReactNode;
}

function Layout({children}: props) {
    const [menuType, setMenuType] = useState<string>('menu-max')
    const [menuState, setMenuState] = useState({display: 'none'})
    const [caret, setCaret] = useState<string>('caretNotRotate')
    const [menuText, setMenuText] = useState({display: 'auto'})
    const [dropState, setDropState] = useState<string>('hide-userD')

    const menuAction = () => {
        if (menuType === 'menu-max') {
            setMenuType('menu-min')
        } else {
            setMenuType('menu-max')
        }
    }

    const changeManuState = () => {
        if (menuState.display === "none") {
            setMenuState({display: 'flex'})
            setCaret('caretRotate')
            setDropState("show-userD")
        } else {
            setMenuState({display: 'none'})
            setCaret('caretNotRotate')
            setDropState("hide-userD")
        }
    }

    return (
        <div className='layout-container'>
            <aside className={menuType}>
                <div className='aside-heading'>
                    <h1>DAGON</h1>
                    <button style={{flex: 0}} onClick={menuAction}><FontAwesomeIcon icon={faBars}/></button>
                </div>
                <div className='aside-menu'>
                    <ul>
                        <li>
                            <FontAwesomeIcon className='menu-icon' icon={faHouse}/>
                            <span style={menuText} className='menu-text'>Dashboard</span>
                        </li>
                        <li>
                            <FontAwesomeIcon className='menu-icon' icon={faRobot}/>
                            <span style={menuText} className='menu-text'>Bots</span>
                        </li>
                        <li>
                            <FontAwesomeIcon className='menu-icon' icon={faScroll}/>
                            <span style={menuText} className='menu-text'>Scripts</span>
                        </li>
                        <li>
                            <FontAwesomeIcon className='menu-icon' icon={faQuestionCircle} />
                            <span style={menuText} className='menu-text'>Q&A</span>
                        </li>
                    </ul>
                </div>
            </aside>
            <section>
                <header>
                    <div className='user-avatar-container'>
                        <div onClick={changeManuState} className='user-avatar-use'>
                            <div className='user-avatar' style={{backgroundImage: `url('${avatar}')`}}></div>
                            <span>iseeyouamadeus</span>
                            <FontAwesomeIcon className={caret} icon={faCaretDown}/>
                        </div>
                        <div className={`user-dropdown ${dropState}`}>
                            <div className='before'/>
                            <div className='cont'>
                                <a href='/profile'>Profile</a>
                                <a href='/settings'>Settings</a>
                                <a href='/settings'>About DAGON</a>
                                <hr/>
                                <a style={{color: 'red'}}>Log Out</a>
                            </div>
                        </div>
                    </div>
                </header>
                <div className='layouted-content'>
                    {children}
                </div>
            </section>
        </div>
    )
}

export default Layout