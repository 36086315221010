import { useState } from 'react'
import './login.css'

const LoginPage = () => {
  const [errors, setErrors] = useState<string[]>([])
  const [login, setLogin] = useState<string>("")
  const [pass, setPass] = useState<string>("")
  const [checkbox, setCheckbox] = useState<boolean>(false)

  const submitFunc = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (login === "") {
      setErrors([...errors, "Login is empty"])
    }

    if (pass === "") {
      setErrors([...errors, "Passwords not mach or empty"])
    }

    if (errors.length !== 0) return console.log(errors)

    alert("logined")
  }

  return (
    <div className='login-container'>
      <h1>Sign In</h1>
      <form onSubmit={submitFunc}>
        <input onChange={(e) => {setLogin(e.target.value)}} placeholder='Username'/>
        <input onChange={(e) => {setPass(e.target.value)}} placeholder='password'/>
        <label className='checkbox'><input onChange={() => {setCheckbox(!checkbox)}} type={'checkbox'} name="remember"/>Remember me</label>
        <button type='submit'>Sign In</button>
      </form>
    </div>
  )
}

export default LoginPage;