import { useState } from 'react';
import './alert.css'

type props = {
    type: string;
    title: string;
    children: string;
    errorsMod: Function;
}

const Alert = ({type, title, children, errorsMod}: props) => {
    const [render, setRender] = useState<boolean>(true)
    
    setTimeout(() => {
        setRender(false)
        errorsMod([])
    }, 2000)
    let classN: string = "alert-container" 
    if (type != null) {
        classN = `alert-container ${type}`
    }
    if (render) {
        return (
            <div className={classN}>
                <h2>{title}</h2>
                <p>{children}</p>
            </div>
        )
    } else {
        return <div></div>
    }
}

export default Alert;